import { Component, HostListener, OnInit } from '@angular/core';
import {
  EventTypes,
  LoginResponse,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  filter,
  lastValueFrom,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { takeUntil } from 'rxjs/operators';
import { IdentityService } from 'src/app/services/identity/identity.service';
import {
  ModifierKeys,
  Shortcut,
} from 'src/app/modules/shared/shortcut/shortcut.model';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { TerminalCommandService } from 'src/app/modules/shared/components/terminal/terminal-command.service';
import { CommandCollector } from 'src/app/commands/command-collector';
import { GuiService } from 'src/app/services/gui.service';
import { Router } from '@angular/router';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { Logger } from 'src/app/services/logger/logger.service';

const log = new Logger('AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isSidebarOpen = true;

  title = 'Admin';
  isLoggedIn = false;
  devConsole = false;
  shortcuts: Shortcut[] = [
    {
      key: `${ModifierKeys.Ctrl}+F12`,
      description: 'Dev console',
      command: () => {
        this.devConsole = !this.devConsole;
      },
    },
  ];
  private refreshInterval: NodeJS.Timeout | null = null;

  private unsubscribe$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  async onResize() {
    this.guiService.checkWindowSize();
  }

  constructor(
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private spinner: SpinnerService,
    private sidebar: SidebarService,
    private identityService: IdentityService,
    private settings: PersonalSettingsService,
    private terminalCommands: TerminalCommandService,
    private commandCollector: CommandCollector,
    private guiService: GuiService,
    private oidcEvents: PublicEventsService,
    private router: Router
  ) {
    this.spinner.show();

    this.sidebar.isSidebarOpen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.isSidebarOpen = value;
      });
    this.registerOidcEvents();
    this.oidcSecurityService
      .checkAuth()
      .subscribe(async (loginResponse: LoginResponse) => {
        console.log('app authenticated', loginResponse.isAuthenticated);
        if (!loginResponse.isAuthenticated) {
          console.log('start login');
          sessionStorage.setItem('originUrl', this.router.url);
          this.oidcSecurityService.authorize();
          return;
        }

        const originUrl = sessionStorage.getItem('originUrl');
        if (originUrl) {
          sessionStorage.removeItem('originUrl');
          this.router
            .navigateByUrl(originUrl)
            .catch(e => console.error("Can't navigate to originUrl", e));
        }

        this.identityService
          .getLoggedInIdentityUser()
          .pipe(
            catchError(e => {
              throw e;
            })
          )
          .subscribe(u => {
            console.log('got user data');
            this.identityService.identityUser$.next(u);
            this.isLoggedIn = true;
            if (this.refreshInterval) clearInterval(this.refreshInterval);
            this.refreshInterval = setInterval(() => {
              this.oidcSecurityService
                .getAccessToken()
                .pipe(take(1), filter(isTokenExpired))
                .subscribe(() =>
                  navigator.locks.request(
                    'refresh',
                    { mode: 'exclusive' },
                    async () =>
                      await lastValueFrom(
                        this.oidcSecurityService.getAccessToken().pipe(
                          take(1),
                          filter(isTokenExpired),
                          switchMap(() =>
                            this.oidcSecurityService.forceRefreshSession()
                          )
                        ),
                        { defaultValue: null }
                      )
                  )
                );
            }, 5000);
          });
      });
  }

  async ngOnInit() {
    this.translateService.use(
      (await this.settings.getSetting('lang')) ||
        this.translateService.defaultLang
    );
    this.addTerminalCommands();
  }

  addTerminalCommands() {
    this.terminalCommands.addCommands(this.commandCollector.get());
  }

  registerOidcEvents() {
    this.oidcEvents
      .registerForEvents()
      .pipe(
        filter(notification =>
          [
            EventTypes.SilentRenewFailed,
            EventTypes.ConfigLoadingFailed,
            EventTypes.TokenExpired,
            EventTypes.IdTokenExpired,
          ].includes(notification.type)
        )
      )
      .subscribe(value => console.warn(EventTypes[value.type], value.value));
  }
}

function isTokenExpired(token: string) {
  const content = parseJwt(token);
  const lifetimeInSeconds = differenceInSeconds(
    fromUnixTime(content.exp),
    new Date()
  );
  log.trace('access token lifetime in seconds: ', lifetimeInSeconds);
  return lifetimeInSeconds < 60;
}

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
